import "./App.css";
import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import Hero from "./components/Hero/Hero";
import Levels from "./components/Levels/Levels";
import CertificateFinal from "./components/CertificateFinal/CertificateFinal";
import CertificateAll from "./components/CertificateAll/CertificateAll";
import Portfolios from "./components/Portfolios/Portfolios";
import Bio from "./components/Bio/Bio";
import SocialMedia from "./components/SocialMedia/SocialMedia";
import Footer from "./components/Footer/Footer";
import LottieLoading from "./components/LottieLoading/LottieLoading";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  if (loading) {
    return <LottieLoading />;
  }

  return (
    <div className="App">
      <Link to="Hero" smooth={true}>
        <Hero />
      </Link>
      <Levels />
      <CertificateFinal />
      <CertificateAll />
      <Portfolios />
      <Link to="Bio" smooth={true}>
        <Bio />
      </Link>
      <SocialMedia />
      <Footer />
    </div>
  );
}

export default App;
