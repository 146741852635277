import React from 'react';
import Lottie from 'react-lottie-player';
import animationData from './LottieLoading.json';

const LottieLoading = () => {
  return (
    <div style={styles.loader}>
      <Lottie
        loop
        animationData={animationData}
        play
        style={{ width: 800, height: 800 }}
      />
    </div>
  );
};

const styles = {
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#000000',
  },
};

export default LottieLoading;
