import React from 'react';
import "./CertificateAll.css";
import {Swiper, SwiperSlide, useSwiper} from 'swiper/react';
import 'swiper/css';
import data from '../../Data/certificateAll';
import { sliderSettings } from '../../Data/Common';
import ClickHere from "../../images/clickhere.png";

const CertificateAll = () => {
    return (
            <section className="cs-wrapper">
            <div className="cs-container">
                <p className='title'>I got the Front-End certificate with the provided documents :</p>
                <Swiper {...sliderSettings}>
                <SliderButtons></SliderButtons>
                    {
                        data.map((card, i) => (
                            <SwiperSlide key={i}>
                                <div className="cs-card">
                                <a href={card.link} target="_blank" rel="noopener noreferrer">

                                   <img src={card.image} alt="Certificate" />
                                   <div className="title-cards">
                                    <img src={ClickHere} alt="ClickHere" className='clickHere' />
                                    <span className="title">{card.name}</span>
                                    </div>
                                    <span className="text-desc">{card.detail}</span>
                                    </a>
                                </div>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
                </div>
        </section>
    );
}

export default CertificateAll;

const SliderButtons = () => {
    const Swiper = useSwiper();
    return (
        <div className="cs-buttons">
            <button onClick={()=> Swiper.slidePrev()}>&lt;</button>
            <button onClick={()=> Swiper.slideNext()}>&gt;</button>
        </div>
    )
}