import React from 'react';
import "./CertificateFinal.css";
import MetaFrontEnd from "../../images/meta-final-front-end.png";
import verified from "../../images/verified-48.png";

const CertificateFinal = () => {
    return (
        <section className='certificate-container' >
            <p className='style-title'>Meta's Front-End Certification</p>
            <div className="certificate">
                <img src={MetaFrontEnd} alt="Front end certificate" />
            </div>
            <div className="text-center">
            <p className='text-desc text-desc-mobile'>
            I successfully obtained the <b>Front-End Developer Professional certification</b> from <b>Meta</b> after completing an extensive course.<br/><br className='br'/>This certification signifies my achievement after completing a lengthy Front-End development program through Coursera.
            </p>
            <div className="verify">
            <img src={verified} alt="School" className='school-icon'/>
            <p className='text-desc text-mobile'>
            Curious about the legitimacy? <a target='_blank' className='text-link' href="https://coursera.org/verify/professional-cert/9UN67SERGE97">Click here!</a>
            </p>
            </div>
            </div>
        </section>
    );
}

export default CertificateFinal;
