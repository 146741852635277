import React from 'react';
import "./Portfolios.css"
import {Swiper, SwiperSlide, useSwiper} from 'swiper/react';
import 'swiper/css';
import portfolioData from '../../Data/portfolioData';
import { sliderSettings } from '../../Data/Common';
import ClickHere from "../../images/clickSite.png";
import Project from "../../images/Project.png"
import Submit from "../../images/submit.png"

const Portfolios = () => {
    return (
        <section className="ps-wrapper">
            <div className="ps-container">
                <div className="title-ps">
                <img src={Project} alt="Projects" className='project-icon'/>
                <p className='style-title'>My Online Portfolio :</p>
                </div>
                <p className='text-desc'>Crafted Codes, From Zero to One Hundred!</p>
                <Swiper {...sliderSettings}>
                <SliderButtons></SliderButtons>
                    {
                        portfolioData.map((card, i) => (
                            <SwiperSlide key={i}>
                                <div className="ps-card">
                                <a href={card.link} target="_blank" rel="noopener noreferrer">
                                   <img src={card.image} alt="Certificate" />
                                   <div className="title-cards">
                                    <img src={ClickHere} alt="ClickHere" className='clickHere' />
                                    <span className='text-click'>Enter Site</span>
                                    <img src={Submit} alt="Project" className='submit-icon' />
                                    <span className="title titleP">{card.name}</span>
                                    </div>
                                    </a>
                                </div>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
                </div>
        </section>
    );
}

export default Portfolios;

const SliderButtons = () => {
    const Swiper = useSwiper();
    return (
        <div className="ps-buttons">
            <button onClick={()=> Swiper.slidePrev()}>&lt;</button>
            <button onClick={()=> Swiper.slideNext()}>&gt;</button>
        </div>
    )
}
