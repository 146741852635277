import React from 'react';
import "../Hero/Hero.css";
import meHero from "../../images/me-hero.png";
import meHeroSm from "../../images/me-hero-sm.png";
import NumberCounter from "number-counter";
import HeroBack from "../../images/heroBack.png";
import meHeroMobile from "../../images/meHeroMobile.png";
import { motion } from "framer-motion";


const Hero = () => {

    const transition = {type: 'spring' , duration : 3}

    return (
        <section className='hero' id="Hero">

            <motion.img
            initial={{bottom : '-5rem'}}
            whileInView={{top : '3rem'}}
            transition={transition} 
            src={HeroBack} alt="HeroBack" className='heroBack'/>
            
            <motion.img
            initial={{top : '-3rem'}}
            whileInView={{top : '8rem'}}
            transition={transition} 
            src={meHeroSm} alt="meHeroSm" className='meHero-sm' />

            <motion.img
            initial={{top : '-3rem'}}
            whileInView={{top : '0'}}
            transition={transition} 
            src={meHero} alt="hero" className='meHero'/>

            <motion.img 
            initial={{top : '-3rem'}}
            whileInView={{top : '2rem'}}
            transition={transition}
            src={meHeroMobile} alt="meHeroMobile" className='meHeroMobile' />

            <div className="blur hero-blur"></div>

{/**
 * figures
 */}
            <div className="figures">
                <div>
                <span>
                    <span className='colorBlue'>+</span>
                    <NumberCounter start={1} end={10} delay={1} prevFix='+'></NumberCounter>
                </span>
                <span>
                Global certificates
                </span>
                </div>

                <div>
                <span>
                    <span className='colorBlue'>+</span>
                    <NumberCounter start={1} end={25} delay={2} prevFix='+'></NumberCounter>
                </span>
                <span>
                Registered projects
                </span>
                </div>
            </div>
            
        </section>
    );
}

export default Hero;
