import React from 'react';
import "./Bio.css";
import BioImg from "../../images/BioImg.png";
import mataBadge from "../../images/meta-front-end-developer-certificate.png";
import { motion } from "framer-motion";

const Bio = () => {

    const transition = {type: 'spring' , duration : 3}

    return (
        <section className='bio-container' id="bio">
            <div className="bio-left">
            <div className="img-bio">
                    <motion.img
                    initial={{left : '-5rem'}}
                    whileInView={{left : '1rem'}}
                    transition={transition} 
                    src={BioImg} alt="bio image" />
                </div>
            </div>

            <div className="bio-right">
                <p className='color2x'>
                Decoding Life, The Amir Mazban Story
                </p>
                <p className='text-desc'>
                I'm Amir Mazban, born in 2004. From a young age, the world of computers and the internet has been my fascination, fostering a dream to excel in this dynamic field. My exploration led me to the world of programming, where I aspired to become a visionary creator of immersive and cutting-edge websites.<br /><br className='br' /><br />

In pursuit of superior learning opportunities, I embarked on a journey of immigration, facing and overcoming challenges that became the driving force behind my professional growth in web development. Through immersive projects, I not only elevated my skills but also evolved from handling small-scale endeavors to independently crafting websites that once demanded a collaborative effort.<br /><br className='br' /><br />

My commitment to excellence led me to enroll in Meta's Front-End Professional course, where I refined my skills to perfection, culminating in the attainment of an international certificate. Now, fueled by passion, I dedicate myself to crafting modern, client-focused websites, with each project serving as a testament to my unwavering commitment and mission.<br />
                </p>
                
                <img src={mataBadge} alt="Mata Badge" className='metaBadge'/>
            </div>
        </section>
    );
}

export default Bio;
