import React from 'react';
import "./SocialMedia.css"

const Payments = () => {
    return (
        <section className='sm-container'>
            <a href="https://www.linkedin.com/in/amirmazban/" target='_blank'>
            <button className='btn sm-btn-1'>Linkedin</button>
            </a>

            <a href="https://www.instagram.com/AmirMazbanOfficial/" target='_blank'>
            <button className='btn sm-btn-2'>Instagram</button>
            </a>

            <a href="https://t.me/AmirMazbanOfficial" target='_blank'>
            <button className='btn sm-btn-3'>Telegram</button>
            </a>

            <a href='mailto:AmirMazbanOfficial@gmail.com?subject=Collaboration&body=Hello%20Amir%20Mazban!%20I%20am%20reaching%20out%20to%20inquire%20about%20the%20possibility%20of%20collaborating%20with%20you.' target='_blank'>
            <button className='btn sm-btn-4'>MyEmail</button>
            </a>
        </section>
    );
}

export default Payments;
