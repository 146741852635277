const certificateData = [
  {
    name: "Front-End Capstone",
    detail:
      "Front-End Developer Capstone: Elevating Skills, Empowering Creativity.",
    image: require("../images/Front-End-Capstone.png"),
    link: "https://coursera.org/verify/VMFT8YYQ2VZL",
  },
  {
    name: "Coding Interview Readiness",
    detail:
      "Embarking on a Personal Coding Odyssey: Journeying Towards Interview Triumph.",
    image: require("../images/Coding-Interview-Readiness.png"),
    link: "https://coursera.org/verify/48CRB6PY3G63",
  },
  {
    name: "Advanced React",
    detail:
      "Comprehensive guide to mastering advanced React concepts and techniques.",
    image: require("../images/Advanced-React.png"),
    link: "https://coursera.org/verify/H787DSUPG4T6",
  },
  {
    name: "Programming JavaScript",
    detail:
      "I've successfully obtained the Programming with JavaScript certification from Meta.",
    image: require("../images/Programming-with-JavaScript.png"),
    link: "https://coursera.org/verify/VWGCR5S2PFNY",
  },
  {
    name: "HTML and CSS in depth",
    detail:
      "Exploring web development intricacies, diving deep into HTML/CSS essentials on a personal journey.",
    image: require("../images/HTML-CSS.png"),
    link: "https://coursera.org/verify/Y3SUNELGKMBW",
  },
  {
    name: "Principles of UX/UI Design",
    detail:
      "Embarking on My Personal Journey: Exploring the Rich Tapestry of UX/UI Design Principles.",
    image: require("../images/UXUI.png"),
    link: "https://coursera.org/verify/8PED3PV4T4B6",
  },
  {
    name: "Introduction to Front-End",
    detail:
      "Discovering the Art of Front-End Development: An Intriguing Introduction to the Craft.",
    image: require("../images/Introduction-FrontEnd.png"),
    link: "https://coursera.org/verify/DP3LU465JGJT",
  },
  {
    name: "Version Control",
    detail:
      "Received my Meta Version Control cert: Code mastery, confidently and officially certified!",
    image: require("../images/Version-Control.png"),
    link: "https://coursera.org/verify/NF65FTHU2UXN",
  },
  {
    name: "React Basics",
    detail:
      "Delving into the World of React: From the Fundamentals to Advanced Techniques.",
    image: require("../images/React-Basics.png"),
    link: "https://coursera.org/verify/NELQX2MYAPRA",
  },
];

export default certificateData;
