import React from 'react';
import "./Footer.css";
import { Link } from 'react-scroll';
import Btc from "../../images/bitcoin.svg"
import Bank from "../../images/bank-transfer.svg"
import Mastercard from "../../images/mastercard.svg"
import Payoneer from "../../images/payoneer.svg"
import Paypal from "../../images/paypal.svg"
import Visacard from "../../images/visacard.svg"

const Footer = () => {
  return (
    <section className="f-container">
      <div className="f-content">
        <div className="copyRight">
          <p className='text-desc'>
            All content and coding of this website are created by 
            <span className='text-link' onClick={() => {}}>
              <Link to="Hero" smooth={true}>
                <b>Amir Mazban</b>
              </Link>
            </span>
             © 2024 All rights reserved.
          </p>                
        </div>

        <div className="payments">
          <img src={Btc} alt="Btc" className='btc' />
          <img src={Bank} alt="Bank" className='bank' />
          <img src={Mastercard} alt="Mastercard" className='mastercard' />
          <img src={Payoneer} alt="Payoneer" className='payoneer' />
          <img src={Paypal} alt="Paypal" className='paypal' />
          <img src={Visacard} alt="Visacard" className='visacard' />
        </div>
      </div>
    </section>
  );
}

export default Footer;
