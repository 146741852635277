import React from 'react';
import "./Levels.css"
import { levelsData } from '../../Data/levelsData'; 
import { Link } from 'react-scroll';


const SliderLevels = () => {
  return (
    <div className='card-container'>
      <div className="title">
        <p className='style-title'>My Front-End Development Journey!</p>
      </div>

      <div className="card-wrapper">
        {levelsData.map((item, index) => (
          <div key={index} className="card">
            <img src={item.image} alt="imageCart" className='imageLevels' />
            <div className="blur level-blur"></div>
            <img src={item.icon} alt="icon" className='iconLevels'/>
            <div className="overlay">
              <div className="content">
                <div className="border-top"></div>
                <p>{item.text}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      
      <Link to="bio" smooth={true} duration={1000}>
    <button className='btn'>MY BIO</button>
    </Link>
    </div>
  );
}

export default SliderLevels;
